import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./monster-rating.scss"

const MonsterRating = ({ level }) => {
  const rating = Array.from(Array(level).keys()).map(i => (
    <StaticImage
      key={i}
      title={`level ${i + 1}`}
      alt={`level ${i + 1}`}
      src="assets/star_monster.png"
      width={25}
    />
  ))

  return <div className="monster-rating">{rating}</div>
}

export default MonsterRating
