import React from "react"
import Header from "./header"
import "./layout.scss"
import Footer from "./footer"
import CookieConsent from "./CookieConsent"

const Layout = ({ itemType, children }) => {
  return (
    <>
      <div className="main-container">
        <Header></Header>
        {itemType ? (
          <main itemScope itemType={itemType} className="main-content">
            {children}
          </main>
        ) : (
          <main className="main-content">{children}</main>
        )}
        <Footer></Footer>
      </div>
      <CookieConsent />
    </>
  )
}

export default Layout
