import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./header.scss"

const Header = () => {
  return (
    <header className="main-header">
      <a name="top" href="/" aria-hidden="true">
        <StaticImage
          alt="Makers & Breakers"
          src="assets/logo.png"
          width={200}
        />
      </a>
    </header>
  )
}

export default Header
