import React from "react"
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { StaticImage } from "gatsby-plugin-image"
import "./CookieConsent.scss"
import { Trans, Link, useTranslation } from "gatsby-plugin-react-i18next"

const isBrowser = () => typeof window !== "undefined"

const getValue = (key, defaultValue) =>
  isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue

const setValue = (key, value) =>
  window.localStorage.setItem(key, JSON.stringify(value))

function useStickyState(key, defaultValue) {
  const [value, setter] = React.useState(() => getValue(key, defaultValue))

  React.useEffect(() => setValue(key, value), [key, value])

  return [value, setter]
}

const CookieConsent = () => {
  const { t, i18n } = useTranslation("common")
  const location = useLocation()
  if (isBrowser()) {
    initializeAndTrack(location)
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    "consentCookieHidden",
    false
  )

  const enableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true"
    setBannerHidden(true)
  }

  return (
    <>
      {!bannerHidden && (
        <div className="consent-wrapper">
          <div className="consent-container">
            <StaticImage
              alt="cookie jar"
              src="assets/cookie_jar.png"
              width={125}
            />
            <div className="consent">
              <h2>{t("CONSENT.TITLE")}</h2>

              <p>
                {" "}
                <Trans
                  i18nKey="CONSENT.TEXT"
                  components={{
                    l: (
                      <Link
                        to="/privacy-policy#cookies"
                        language={i18n.language}
                      />
                    ),
                  }}
                />
              </p>
            </div>
            <button className="consent-button" onClick={enableAnalytics}>
              OK
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieConsent
