/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./home.scss"
import MonsterRating from "../components/monster-rating"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProjectItem = ({ item }) => {
  return (
    <div className="project-item">
      <header>
        <h2>{item.title}</h2>
      </header>
      <Link to={item.slug}>
        <GatsbyImage image={getImage(item.thumbnail)} alt={item.title} />
      </Link>
      <footer>
        <summary>{item.description}</summary>
        <MonsterRating level={item.level}></MonsterRating>
      </footer>
    </div>
  )
}

const Home = ({ data }) => {
  const { t } = useTranslation()
  const projects = data.allMdx.nodes.map(n => ({
    ...n.frontmatter,
    ...n.fields,
  }))
  return (
    <Layout>
      <SEO title={t("common:TITLE")}></SEO>
      <div className="story-container">
        <div className="story">
          <h2 className="header">{t("join:STORY.OUR.TITLE")}</h2>
          <p>{t("join:STORY.OUR.TEXT")}</p>
        </div>
        <div className="story">
          <h2 className="header">{t("join:STORY.YOURS.TITLE")}</h2>
          <p>{t("join:STORY.YOURS.TEXT")}</p>
        </div>
      </div>
      <h1 className="header">{t("projects:TITLE")}</h1>
      <div className="project-container">
        {projects.map((item, idx) => (
          <ProjectItem key={idx} item={item}></ProjectItem>
        ))}
      </div>
    </Layout>
  )
}
export default Home

export const projectsQuery = graphql`
  query AllProjects($language: String!) {
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { lang: { eq: $language } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date
          description
          level
          title
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                height: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
