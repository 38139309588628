import React, { useRef } from "react"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { useDetectOutsideClick } from "../hooks/detect-outside-click"
import "./footer.scss"
import { locales, languages } from "../util/languages"

const LangSwitcher = () => {
  const { originalPath } = useI18next()
  const { t, i18n } = useTranslation("common")
  const dropdownRef = useRef(null)
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false)
  const onClick = () => setIsActive(!isActive)

  return (
    <div className="language-container">
      <label>{t("LANGUAGE.SELECTION_TEXT")}</label>
      <div className="language-selector">
        <button className="language-trigger" onClick={onClick}>
          <span>{locales[i18n.language].locale}</span>
        </button>
        <nav
          ref={dropdownRef}
          className={`language-menu ${
            isActive ? "language-menu__active" : "language-menu__inactive"
          }`}
        >
          <ul>
            {languages.map(lng => (
              <li key={lng} value={lng}>
                <Link to={originalPath} language={lng}>
                  {locales[lng].locale}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

const Footer = () => {
  const { t, i18n } = useTranslation("common")
  return (
    <footer className="main-footer-container">
      <div className="main-footer">
        <span>© {new Date().getFullYear()}</span>
        <LangSwitcher></LangSwitcher>
      </div>
      <nav className="secondary-navigation">
        <Link
          to="/impressum"
          language={i18n.language}
          className="secondary-navigation-item"
        >
          {t("IMPRINT")}
        </Link>
        <Link
          to="/privacy-policy"
          language={i18n.language}
          className="secondary-navigation-item"
        >
          {t("PRIVACY_POLICY")}
        </Link>
      </nav>
    </footer>
  )
}

export default Footer
